import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const useFirebase = () => {
  const {
    REACT_APP_FIREBASE_KEY,
    REACT_APP_AUTH_DOMAIN,
    REACT_APP_PROJECT_ID,
    REACT_APP_STORAGE_BUCKET,
    REACT_APP_MESSAGING_SENDER_ID,
    REACT_APP_APP_ID,
  } = process.env;

  const firebaseConfig = {
    apiKey: REACT_APP_FIREBASE_KEY,
    authDomain: REACT_APP_AUTH_DOMAIN,
    projectId: REACT_APP_PROJECT_ID,
    storageBucket: REACT_APP_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
    appId: REACT_APP_APP_ID,
  };

  const app = initializeApp(firebaseConfig);
  const firestore = getFirestore(app);

  return { app, firestore };
};

export default useFirebase;
